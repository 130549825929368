import React from 'react';
import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  Avatar,
  useColorModeValue,
} from '@chakra-ui/react';
import Ray from './ray1.png';

export default function RayTracer() {
  return (
    <>
      <Box align={'center'} mt={-3} mx={-3} mb={6} pos={'relative'}>
        <img
          src={Ray}
          w={'445px'}
          style={{ height: '300px' }}
          align={'center'}
          layout={'fill'}
        />
      </Box>
      <Stack spacing={4}>
        <Heading fontSize={'2xl'} fontFamily={'body'}>
          RayTracer
        </Heading>
        <Text>
          I created a 3D ray tracer in the programming language C. The process
          of creating the 3D ray tracer forced me to combine my multivariable
          calculus skills with my linear algebra capabilities. It shows that I
          am able to apply my mathematical skills in unique situations to
          achieve my goals.
        </Text>
        <Text>
          This program successfully traces billions of individual rays of light
          around a virtual room of 3D objects to create an appealing 2D image.
          The Raytracer uses backwards raytracing to simulate light around the
          3D objects. A scene can be created by adding objects to a 3D plane.
          Then a 2D image is generated by tracking the color generated by rays
          of light going through each individual pixel.
        </Text>
        <Text color={'gray.500'}>Fall 2022</Text>
      </Stack>
    </>
  );
}
